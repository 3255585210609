/** @jsx jsx */
import { jsx } from "theme-ui"
import { StickyContainer, Sticky } from "react-sticky"

function StickyHeader({ children, headerContent }) {
  return (
    <StickyContainer>
      <Sticky>
        {({ style, isSticky }) => (
          <div style={style}>{headerContent(isSticky)}</div>
        )}
      </Sticky>
      {children}
    </StickyContainer>
  )
}
export default StickyHeader
