/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Text from "../components/text"
import RichText from "../components/rich-text"
import Link from "../components/link"
import Container from "../components/container"
import { useScrollProgress } from "../hooks/use-scroll-progress"
import StickyHeader from "../components/sticky-header"
import { useIntersection } from "react-use"
import AnimateChildren from "../components/animate-children"

const PageTemplate = ({ data }) => {
  const rootRef = React.useRef(null)
  const tagsRef = React.useRef(null)
  const progress = useScrollProgress(rootRef)
  const intersects = useIntersection(tagsRef, { root: null })
  return (
    <Container>
      <SEO title={data.post.title} description={data.post.teaser.teaser} />
      <StickyHeader
        headerContent={undocked => (
          <div
            sx={{
              backgroundColor: "white",
            }}
          >
            <Text
              type="h2"
              sx={{
                fontSize: undocked ? "24px" : "32px",
                transition: "0.3s",
                py: 2,
              }}
            >
              {data.post.title}
            </Text>
            <div
              sx={{
                backgroundColor: "muted",
                opacity: undocked ? 1 : 0,
                transition: "0.2s",
              }}
            >
              <div
                sx={{
                  height: "2px",
                  backgroundColor: "primary",
                  maxWidth: `${progress * 100}%`,
                }}
              ></div>
            </div>
          </div>
        )}
      >
        <div ref={rootRef}>
          <Text sx={{ pb: 2 }}>{data.post.teaser.teaser}</Text>
          <RichText data={data.post.fullStory} />
        </div>
      </StickyHeader>
      <div ref={tagsRef} sx={{ display: "flex" }}>
        <AnimateChildren
          visible={intersects?.intersectionRatio > 0}
          visibleStyle={{ opacity: 1, top: 0 }}
          invisibleStyle={{ opacity: 0, top: "5px" }}
          sx={{ position: "relative" }}
          delay={0.1}
        >
          {data.post.tags.map(tag => (
            <Link link={`/blog?tags=%5B"${tag}"%5D`} sx={{ mr: 2 }}>
              {tag}
            </Link>
          ))}
        </AnimateChildren>
      </div>
    </Container>
  )
}

export default PageTemplate

export const query = graphql`
  query($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      category
      date
      fullStory {
        json
      }
      slug
      teaser {
        teaser
      }
      title
      tags
    }
  }
`
