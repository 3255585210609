/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { motion } from "framer-motion"

function AnimateChildren({
  children,
  visible,
  visibleStyle,
  invisibleStyle,
  delay,
  transition,
  ...restProps
}) {
  return (
    <React.Fragment>
      {children.map((child, index) => (
        <motion.div
          key={index}
          variants={{
            visible: visibleStyle,
            invisible: invisibleStyle,
          }}
          animate={visible ? "visible" : "invisible"}
          transition={{ delay: index * (delay || 0), ...transition }}
          {...restProps}
        >
          {child}
        </motion.div>
      ))}
    </React.Fragment>
  )
}
export default AnimateChildren
